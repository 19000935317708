<template>
  <div class="navigation_Bar">
    <div class="menu_box" v-show="showBack">
      <div class="home_back" @click="handelBack()">
        <img src="@/assets/images/home_back.png" alt="">
      </div>
      <div class="menu_home" @click="handelHome()">
        <img src="@/assets/images/menu_home.png" alt="">
      </div>
      <div class="line"></div>
    </div>
    <h2><slot></slot></h2>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {
      break_url: ''
    };
  },
  components: {},
  methods: {
    handelBack() {
      if(this.home == 'home' && localStorage.getItem('retUrl')) {
        window.location = localStorage.getItem('retUrl');
        return
      }
      if(this.back == 1 && this.out == 'out') {
        // this.break_url = this.$route.query.break_url;
        // if(this.break_url) {
        //   var hm = document.createElement("script");
        //   hm.src = this.break_url;
        //   var s = document.getElementsByTagName("script")[0];
        //   s.parentNode.insertBefore(hm, s);
        // }
        
      }
      if(this.back == 1) {
        this.$router.go(-2);
      } else {
        this.$router.go(-1);
      }
    },

    handelHome() {
      this.$router.push('/');
    }
  },
  props: {
    showBack: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    back: {
      default: () => {
        return 0
      }
    },
    out: {
      default: () => {
        return ''
      }
    },
    home: {
      default: () => {
        return ''
      }
    },
  }
};
</script>

<style scoped lang="less">
.navigation_Bar {
  width: 100%;
  height: 0.88rem;
  background: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;

  h2 {
    font-size: 0.34rem;
    font-family: "PingFangSC-Regular";
    font-weight: 600;
    color: #000000;
    text-align: center;
    line-height: 0.88rem;
  }

  .menu_box {
    width: 1.75rem;
    height: 0.64rem;
    border: 0.01rem solid #e1e1e1;
    border-radius: 0.5rem;
    position: absolute;
    left: 0.11rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.24rem 0 0.34rem;
    
    .home_back {
      img {
        width: 0.21rem;
        height: 0.35rem;
      }
    }

    .menu_home {
      img {
        width: 0.42rem;
        height: 0.42rem;
      }
    }

    .line {
      width: 0.01rem;
      height: 0.64rem;
      position: absolute;
      top: 0;
      left: 0.84rem;
      background-color: #e1e1e1;
    }
  }
}

</style>
