<template>
  <div class="MBTI">
    <navigationBar :showBack="showBack">{{ dataList.name }}</navigationBar>
    <img class="banner" :src="dataList.imageUrl" alt="" />
    <div class="title_box">
      <h3>{{ dataList.name }}</h3>
      <div>
        <img class="yice" src="@/assets/images/yice.png" alt="" />
        <span>已测:{{ dataList.finishedNum }}人</span>
        <img class="line" src="@/assets/images/line.png" alt="" />
        <img class="timu" src="@/assets/images/timu.png" alt="" />
        <span>题目:{{ dataList.questionNum }}道</span>
      </div>
    </div>
    <div class="text_box">
      {{ dataList.description }}
    </div>
    <div class="btn_bottom">
      <div class="doubt" @click="showDoubt = true">
        我有疑惑
      </div>
      <div
        class="btn"
        v-if="dataList.finished == 0"
        @click="handelStart(dataList.finished, dataList.code, dataList.name)"
      >
        立即测评
      </div>
      <div
        class="btn report"
        v-if="dataList.finished == 1"
        @click="
          handelEvaluationReport(
            dataList.finished,
            dataList.code,
            dataList.name
          )
        "
      >
        测评报告
      </div>
      <div
        class="btn begin"
        v-if="dataList.finished == 2"
        @click="handelStart(dataList.finished, dataList.code, dataList.name)"
      >
        继续测评
      </div>
    </div>
    <van-popup v-model="showNotice"
      ><notice
        v-if="showNotice"
        :finished="finished"
        :evaluationId="evaluationId"
      ></notice
    ></van-popup>
     <van-popup v-model="showDoubt"
      >
        <div class="doubt_box">
          <img class="close" @click="showDoubt = false" src="https://file.jycyfww.com/wangchuang/qc/prod/img/product/c603de2fd78a4672a8e4e9f861ac1474.png" alt="">
          <p>长按二维码添加客服微信</p>
          <div class="code">
            <img src="https://file.jycyfww.com/wangchuang/qc/prod/img/product/a881fb723e26481cb63a6f2b2376afa3.jpg" alt="">
          </div>
        </div>
      </van-popup>
  </div>
</template>

<script type="text/ecmascript-6">
import navigationBar from "@/components/navigationBar/navigationBar";
import notice from "@/components/notice/notice";
import { getEvaluationDetail } from "@/request/api";
export default {
  data() {
    return {
      showBack: true,
      id: 0,
      dataList: {},
      showNotice: false,
      finished: 0,
      evaluationId: 0,
      loginUser: {},
      showDoubt: false
    };
  },
  components: {
    navigationBar,
    notice,
  },
  created() {
    this.id = this.$route.query.id;
    this.loginUser = JSON.parse(localStorage.getItem("loginUser"));
  },
  mounted() {
    this.getEvaluationDetail(this.id);
  },
  methods: {
    // 开始测评
    handelStart(finished, id, name) {
      if (this.loginUser) {
        this.finished = finished;
        this.evaluationId = id;
        // this.showNotice = true;
        this.$router.push({
          path: "/notice",
          query: { finished: this.finished, evaluationId: this.evaluationId },
        });
        localStorage.setItem("name", JSON.stringify(name));
      } else {
        this.$router.push("/Login");
      }
    },

    // 测评报告
    handelEvaluationReport(finished, id, name) {
      this.$router.push({ path: "/report", query: { code: id } });
      // 
      localStorage.setItem("name", JSON.stringify(name));
    },

    // 测评详情接口
    getEvaluationDetail(id) {
      getEvaluationDetail(id).then((res) => {
        this.dataList = res.data;
      });
    },
  },
};
</script>

<style scoped lang="less">
.MBTI {
  margin-top: 0.88rem;
  padding: 0 0 0.98rem 0;
  min-height: calc(100vh - 0.88rem);
  background-color: #f3f3f3;

  .banner {
    width: 100%;
    height: 3.52rem;
  }

  .title_box {
    background: #ffffff;
    border-radius: 0.12rem 0.12rem 0px 0px;
    padding: 0.4rem 0.42rem 0.22rem 0.42rem;
    position: relative;
    top: -0.2rem;

    h3 {
      font-size: 0.36rem;
      font-family: "PingFangSC-Regular";
      font-weight: 600;
      color: #333333;
      line-height: 0.38rem;
      margin-bottom: 0.3rem;
    }

    div {
      display: flex;
      align-items: center;

      .yice {
        width: 0.23rem;
        height: 0.23rem;
        margin: 0 0.14rem 0 0;
      }

      .line {
        width: 0.02rem;
        height: 0.39rem;
        margin: 0 0.2rem;
      }

      .timu {
        width: 0.21rem;
        height: 0.23rem;
        margin: 0 0.14rem 0 0;
      }

      span {
        font-size: 0.26rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #999999;
        line-height: 0.38rem;
      }
    }
  }

  .text_box {
    padding: 0.4rem 0.35rem;
    background-color: #ffffff;
    font-size: 0.28rem;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: #666666;
    line-height: 0.45rem;
  }

  .btn_bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .doubt {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.32rem;
    font-family: "PingFangSC-Regular";
    font-weight: 500;
    color: #ffffff;
    background: #FC9925;
    height: 0.9rem;
    width: 50%;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.32rem;
    font-family: "PingFangSC-Regular";
    font-weight: 500;
    color: #ffffff;
    background: #0067B0;
    height: 0.9rem;
    width: 50%;
  }

  .report {
    background: #35E699;
  }

  .begin {
    background: #02ADF4;
  }
}

/deep/ .van-popup {
  border-radius: 0.12rem;
  background-color: transparent;
}

.doubt_box {
  width: 6.06rem;
  height: 5rem;
  background: #FFFFFF;
  border-radius: 0.1rem;
  position: relative;
  padding: 0.6rem 0 0 0;

  .close {
    width: 0.31rem;
    height: 0.31rem;
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
  }

  p {
    font-size: 0.28rem;
    font-weight: 400;
    color: #666666;
    text-align: center;
  }

  .code {
    width: 3rem;
    height: 3rem;
    border: 0.02rem solid #CBE9FF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem auto 0;

    img {
      width: 3rem;
      height: 3rem;
      border: 0.02rem solid #CBE9FF;
    }
  }
}
</style>
