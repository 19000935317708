<template>
  <div class="notice">
    <div class="notice_box">
      <h3>测评须知</h3>
      <div class="content">
        <p v-for="(item, index) of dataList.evaluationNotices" :key="index">
          {{item}}
        </p>
        <div class="tips" v-if="timeLine != 0">注意：此测评一旦开始需要在<span>{{time}}</span>之内完成，你是否准备好了？</div>
      </div>
    </div>
    <div class="confirm" @click="handelConfirm()">确定</div>
  </div>
</template>

<script type="text/ecmascript-6">
import { getEvaluationNotice, getEvaluation} from "@/request/api";
export default {
  data() {
    return {
        dataList: {},
        timeLine: 0,
        time: ''
    };
  },
  components: {},
  mounted() {
      this.getEvaluationNotice(this.evaluationId);
      this.getEvaluation(this.evaluationId);
  },
  methods: {
    // 测评须知
    getEvaluationNotice(id) {
      getEvaluationNotice(id).then((res) => {
        this.dataList = res.data;
        localStorage.setItem('subjectIds', JSON.stringify(this.dataList.subjectIds))
      });
    },
    // 测评详情
    getEvaluation(id) {
      getEvaluation(id).then((res) => {
        this.timeLine = res.data.timeLine
        this.time = this.formatSeconds(this.timeLine);
      });
    },
    // 秒转分
    formatSeconds(value) {
      var secondTime = parseInt(value);// 秒
      var minuteTime = 0;// 分
      var hourTime = 0;// 小时
      if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
          //获取分钟，除以60取整数，得到整数分钟
          minuteTime = parseInt(secondTime / 60);
          //获取秒数，秒数取佘，得到整数秒数
          secondTime = parseInt(secondTime % 60);
          //如果分钟大于60，将分钟转换成小时
          if(minuteTime > 60) {
              //获取小时，获取分钟除以60，得到整数小时
              hourTime = parseInt(minuteTime / 60);
              //获取小时后取佘的分，获取分钟除以60取佘的分
              minuteTime = parseInt(minuteTime % 60);
          }
      }
      var result = "" + parseInt(secondTime) + "秒";
      if(minuteTime > 0) {
          result = "" + parseInt(minuteTime) + "分钟" + result;
      }
      if(hourTime > 0) {
          result = "" + parseInt(hourTime) + "小时" + result;
      }
      return result;
    },
    // 确定
    handelConfirm() {
      this.$router.replace({
        path: "/answer",
        query: { finished: this.finished, evaluationId: this.evaluationId },
      });
    },
  },
  props: {
    finished: {
      type: String,
      default: () => {
        return 0;
      },
    },
    evaluationId: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
};
</script>

<style scoped lang="less">
.notice {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 2.46rem;

  .notice_box {
    width: 6.7rem;
    // max-height: 7.76rem;
    background: #ffffff;
    border-radius: 0.12rem;
    padding: 0.3rem 0.3rem 0.48rem 0.3rem;
    margin-bottom: 0.86rem;

    h3 {
      font-size: 0.3rem;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: #0067b0;
      line-height: 0.38rem;
      text-align: center;
      margin-bottom: 0.4rem;
    }

    .content {
      // max-height: 6.29rem;
      overflow: auto;
      p {
        font-size: 0.26rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #666666;
        line-height: 0.43rem;
      }
    }
  }

  .confirm {
    width: 2rem;
    height: 0.7rem;
    background: #0067b0;
    border-radius: 0.45rem;
    text-align: center;
    line-height: 0.7rem;
    font-size: 0.3rem;
    font-family: "PingFangSC-Regular";
    font-weight: 500;
    color: #ffffff;
    margin: 0 auto;
  }

  .tips {
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-top: 0.23rem;
    line-height: 0.42rem;

    span {
      color: #0067B0;
    }
  }
}
</style>
